import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    currentUser: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.currentUser = action.payload
        },
        unsetUser: (state) => {
            state.currentUser = null
        },
    },
})

export const { setUser, unsetUser } = authSlice.actions

export default authSlice.reducer
