import Title from '../atoms/typography/title'
import Text from '../atoms/typography/text'
import LittleText from '../atoms/typography/littleText'
import IconButton from '../atoms/iconButton'
import TrashIcon from '../../assets/icons/trash.svg'
import PencilIcon from '../../assets/icons/pencil.svg'
import CreditCardIcon from '../../assets/icons/card.svg'
import BankNotesIcon from '../../assets/icons/banknotes.svg'
import useDatabase from '../../hooks/useDatabase'
import { showModal } from '../../store/sharedSlice'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import Icon from '../atoms/icon'

const Card = ({
    title,
    notes,
    category,
    subCategory,
    date,
    amount,
    index,
    paidWith,
}) => {
    const trimmedNotes =
        notes && notes.length > 24 ? `${notes.substring(0, 24)}...` : notes

    const { removeData } = useDatabase()
    const dispatch = useDispatch()

    const classes = classNames(
        `transform transition ease-in-out hover:shadow-lg hover:scale-105 select-none mb-2 
        w-full flex flex-col justify-around items-center rounded-md bg-center bg-no-repeat bg-cover h-56`,
        {
            'bg-car': category === 'car',
            'bg-personal': category === 'personal',
            'bg-home': category === 'home',
            'bg-bad_stuff': category === 'bad_stuff',
            'bg-subscriptions': category === 'subscriptions',
        }
    )

    const paidWithIcon =
        paidWith === 'bankNotes' ? BankNotesIcon : CreditCardIcon

    return (
        <div className={classes} key={index}>
            <div className="bg-gray-50/60 w-full h-full">
                <div className="flex justify-end w-full">
                    <div className="flex">
                        <IconButton
                            icon={TrashIcon}
                            onClick={() => removeData(index)}
                            className="p-2"
                        />
                        <IconButton
                            icon={PencilIcon}
                            onClick={() => {
                                dispatch(
                                    showModal({
                                        data: {
                                            notes,
                                            category,
                                            subCategory,
                                            date,
                                            amount,
                                            index,
                                        },
                                    })
                                )
                            }}
                            className="p-2"
                        />
                    </div>
                </div>
                <div className="flex grow flex-col text-left w-full ml-3">
                    <Title className="capitalize">{title}</Title>
                    <Text className="capitalize">{subCategory}</Text>
                </div>
                {trimmedNotes && (
                    <div className="flex flex-col text-left ml-5 mt-3 w-full">
                        <LittleText className="font-bold">
                            Additional notes:
                        </LittleText>
                        <LittleText className="italic">
                            {trimmedNotes}
                        </LittleText>
                    </div>
                )}
                <div className="flex justify-between ">
                    {paidWith && (
                        <Icon icon={paidWithIcon} className="m-auto ml-5" />
                    )}
                    <div className="flex flex-col align-middle w-full text-right p-3">
                        <Title>{`${amount}€`}</Title>
                        <LittleText>{date}</LittleText>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card
