import Title from '../atoms/typography/title'
import PieChart from '../atoms/pieChart'
import BarChart from '../atoms/barChart'

const ChartCard = ({ className, data, title, type = 'pie' }) => {
    return (
        <div
            className={`shadow-lg rounded-md bg-white m-5 p-5 h-fit flex flex-col items-center col-span-2 ${className}`}>
            <Title className="font-bold mb-1">{title}</Title>
            {type === 'pie' && <PieChart data={data} />}
            {type === 'bar' && <BarChart data={data} />}
        </div>
    )
}

export default ChartCard
