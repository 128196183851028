import { useDispatch, useSelector } from 'react-redux'
import { setExpenses } from '../store/dataSlice'
import useDatabase from './useDatabase'

const useSort = () => {
    const dispatch = useDispatch()
    const listKey = useSelector(state => state.data.key)
    const { getExpensesByChild } = useDatabase()

    const sortByKey = async (key, reverse = false) => {
        const sort = await getExpensesByChild(listKey, key, reverse)
        dispatch(setExpenses(sort))
    }

    const sortByDate = async (reverse = false) => {
        const sort = await getExpensesByChild(listKey, 'date', reverse)
        dispatch(setExpenses(sort))
    }

    return { sortByKey, sortByDate }
}

export default useSort
