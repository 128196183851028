import { useSelector } from 'react-redux'
import useCategories from './useCategories'
import moment from 'moment'
import { getKeyByValue, reduceByKeyAndValue } from '../utils/shared'

const imLazy = (accumulator, arr, month) => {
    return Object.assign(
        accumulator,
        arr.reduce(
            (prev, curr) => {
                if (month === moment(curr.value.date).format('MMMM'))
                    prev[month] += parseFloat(curr.value.amount)
                return prev
            },
            { [month]: 0 }
        )
    )
}

const useMath = () => {
    const expenses = useSelector(state => state.data.expenses)

    const { getMacroCategories, getSubCategories } = useCategories()
    const macroCats = getMacroCategories()
    const subCats = getSubCategories()

    const overallTotal = () => {
        return expenses
            .reduce((prev, curr) => prev + parseFloat(curr.value.amount), 0)
            .toFixed(2)
    }

    const currentYearTotal = () => {
        return expenses.reduce((prev, curr) => {
            if (moment(curr.value.date).year() === moment().year())
                prev += parseFloat(curr.value.amount)
            return prev
        }, 0)
    }

    const currentMonthTotal = () => {
        return expenses.reduce((prev, curr) => {
            if (moment(curr.value.date).month() === moment().month())
                prev += parseFloat(curr.value.amount)
            return prev
        }, 0)
    }

    const mostExpensiveByKeyAndDate = (reduceKey, date) => {
        //I really don't like this shit, but now it's a little better
        let accumulator = []
        let filtered = []

        let cats = {
            subCategory: subCats,
            category: macroCats,
        }

        if (date) {
            const { key, value } = date
            filtered = expenses.filter(
                el =>
                    moment(el.value.date).format(key).toLowerCase() ===
                    value.toLowerCase()
            )
        }
        cats[reduceKey].forEach(cat => {
            Object.assign(
                accumulator,
                reduceByKeyAndValue(
                    !!filtered.length ? filtered : expenses,
                    cat,
                    reduceKey
                )
            )
        })
        const amount = Object.values(accumulator).sort((a, b) => b - a)[0]
        const category = getKeyByValue(accumulator, amount)
        return { amount, category }
    }

    const categoriesTotal = () => {
        let accumulator = []
        macroCats.forEach(cat => {
            Object.assign(
                accumulator,
                reduceByKeyAndValue(expenses, cat, 'category')
            )
        })
        return accumulator
    }

    const subCategoriesTotal = () => {
        let accumulator = []
        subCats.forEach(cat => {
            Object.assign(
                accumulator,
                reduceByKeyAndValue(expenses, cat, 'subCategory')
            )
        })
        return accumulator
    }

    const monthsTotalByYear = year => {
        let accumulator = []
        expenses
            .filter(el => moment(el.value.date).year() === moment(year).year())
            .forEach(el => {
                const month = moment(el.value.date).format('MMMM')
                imLazy(accumulator, expenses, month)
            })
        return accumulator
    }

    const totalInCategoriesByMonth = month => {
        const numberMonth = moment(month).format('M')
        const filtered = expenses.filter(
            el => moment(el.value.date).format('M') === numberMonth
        )
        let accumulator = []
        macroCats.forEach(cat => {
            Object.assign(
                accumulator,
                reduceByKeyAndValue(filtered, cat, 'category')
            )
        })
        return accumulator
    }

    const monthsTotalByCategory = category => {
        let accumulator = []
        const filtered = expenses.filter(el => el.value.category === category)
        filtered.forEach(el => {
            const month = moment(el.value.date).format('MMMM')
            imLazy(accumulator, filtered, month)
        })
        return accumulator
    }

    const monthsTotalBySubCategory = subCategory => {
        console.log(subCategory)
        let accumulator = []
        const filtered = expenses.filter(
            el => el.value.subCategory === subCategory
        )
        filtered.forEach(el => {
            const month = moment(el.value.date).format('MMMM')
            imLazy(accumulator, filtered, month)
        })
        return accumulator
    }

    return {
        overallTotal,
        currentYearTotal,
        currentMonthTotal,
        mostExpensiveByKeyAndDate,
        categoriesTotal,
        subCategoriesTotal,
        monthsTotalByYear,
        totalInCategoriesByMonth,
        monthsTotalByCategory,
        monthsTotalBySubCategory,
    }
}

export default useMath
