import { initializeApp } from 'firebase/app'
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    sendPasswordResetEmail,
} from 'firebase/auth'
import { getDatabase, ref } from 'firebase/database'
import {
    getRemoteConfig,
    getValue,
    fetchAndActivate,
} from 'firebase/remote-config'

const config = {
    apiKey: 'AIzaSyCeqSbpD4n0DCFvRbzbBK8i3TaxwZOA2vk',
    authDomain: 'expense-manager-cda54.firebaseapp.com',
    projectId: 'expense-manager-cda54',
    storageBucket: 'expense-manager-cda54.appspot.com',
    messagingSenderId: '97433713367',
    appId: '1:97433713367:web:11124130f93f40df168e92',
}

const firebaseApp = initializeApp(config)
const auth = getAuth(firebaseApp)
const firebaseDatabase = getDatabase(
    firebaseApp,
    'https://expense-manager-cda54-default-rtdb.europe-west1.firebasedatabase.app/'
)
const remoteConfig = getRemoteConfig(firebaseApp)

const createUser = (mail, password) =>
    createUserWithEmailAndPassword(auth, mail, password)
const signIn = (mail, password) =>
    signInWithEmailAndPassword(auth, mail, password)
const logout = () => signOut(auth)
const resetPassword = email => sendPasswordResetEmail(auth, email)
const authListener = cb =>
    onAuthStateChanged(auth, user => {
        cb(user)
    })

export {
    firebaseDatabase,
    remoteConfig,
    ref,
    getValue,
    fetchAndActivate,
    createUser,
    signIn,
    authListener,
    logout,
    resetPassword,
    auth,
}
