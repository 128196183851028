import { useDispatch, useSelector } from 'react-redux'
import LogoutIcon from '../../assets/icons/logout.svg'
import Title from '../atoms/typography/title'
import Text from '../atoms/typography/text'
import { unsetUser } from '../../store/authSlice'
import useAlert from '../../hooks/useAlert'
import IconButton from '../atoms/iconButton'
import { logout } from '../../firebase/firebaseSDK'
import { Link, useNavigate } from 'react-router-dom'
import Menu from './menu'

const Navbar = props => {
    const { className } = props
    const { toggleAlert } = useAlert()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const email = useSelector(state => state.auth.currentUser.email)

    const logoutClick = async () => {
        try {
            await logout()
            dispatch(unsetUser())
            navigate('/auth')
            toggleAlert('Bye dude!', "You're now logged out!", 'SUCCESS')
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <nav
            className={`${className} h-1/6 max-h-20 w-full fixed flex items-center py-4 bg-primary-color shadow-lg z-40`}>
            <div className="w-full flex justify-between px-6">
                <div className="flex flex-row items-center">
                    <Title className="p-2 select-none text-white">
                        Expense tracker
                    </Title>
                    <div className="lg:flex hidden">
                        <Text className="p-2 select-none text-white">
                            <Link to="/dashboard">Home </Link>
                        </Text>
                        <Text className="p-2 select-none text-white">
                            <Link to="/stats">Stats </Link>
                        </Text>
                    </div>
                </div>
                <div className="flex items-center flex-row justify-end">
                    <Text className="hidden lg:block select-none text-white">{`Hi, ${email}`}</Text>
                    <IconButton
                        className="hidden lg:block rounded-full m-2 p-2 select-none bg-primary-color-50"
                        icon={LogoutIcon}
                        onClick={logoutClick}
                    />
                    <div className="lg:hidden">
                        <Menu email={email}>
                            <hr className="h-0 my-2 border border-solid border-t-0 border-gray-700 opacity-25" />
                            <button
                                className="dropdown-item w-full text-sm py-2 px-4 font-normal block whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                                type="button"
                                onClick={logoutClick}>
                                Logout
                            </button>
                        </Menu>
                    </div>
                </div>
            </div>
        </nav>
    )
}
export default Navbar
