import { firebaseDatabase } from '../firebase/firebaseSDK'
import {
    ref,
    set,
    get,
    query,
    remove,
    onValue,
    equalTo,
    orderByChild,
    startAt,
    endAt,
    push,
    limitToFirst,
} from 'firebase/database'
import { useDispatch, useSelector } from 'react-redux'
import { setExpenses, setExpensesDetails, setLists } from '../store/dataSlice'
import moment from 'moment'
import {
    convertObjectToArray,
    convertOrderedQueryToArray,
} from '../utils/shared'

const useDatabase = () => {
    const db = firebaseDatabase
    const uid = useSelector(state => state.auth.currentUser.uid)
    const dispatch = useDispatch()
    const expenses = useSelector(state => state.data.expenses)
    const listKey = useSelector(state => state.data.key)

    // const getLists = () => {
    //     const listsRef = ref(db, `users/${uid}/lists`)
    //     onValue(listsRef, snapshot => {
    //         const data = snapshot.val()
    //         const lists = convertObjectToArray(data)
    //         dispatch(setLists(lists))
    //     })
    // }

    const createList = listName => {
        const userDataRef = ref(db, `/${uid}/lists/`)
        push(userDataRef, { listName, isDefault: true, expenses: [] })
    }

    const getLists = () => {
        const userDataRef = ref(db, `/${uid}/lists/`)
        return onValue(userDataRef, snapshot => {
            let data = snapshot.val()
            dispatch(setLists(convertObjectToArray(data)))
        })
    }

    const queryDefaultList = () => {
        return query(
            ref(db, `/${uid}/lists`),
            orderByChild('isDefault'),
            equalTo(true),
            limitToFirst(1)
        )
    }

    const getExpensesByChild = async (key, child, inverted = true) => {
        const expensesQuery = await get(
            query(ref(db, `/${uid}/lists/${key}/expenses`), orderByChild(child))
        )
        return convertOrderedQueryToArray(expensesQuery, inverted)
    }

    const readData = () => {
        onValue(queryDefaultList(), async snapshot => {
            if (!snapshot.exists()) {
                return
            }

            const list = snapshot.val()
            const key = Object.keys(list)[0]
            const expenses = await getExpensesByChild(key, 'date')

            dispatch(setExpenses(expenses))
            dispatch(
                setExpensesDetails({
                    key,
                    listName: list[key].listName,
                })
            )
        })
    }

    const writeData = data => {
        const expensesRef = ref(db, `/${uid}/lists/${listKey}/expenses/`)
        push(expensesRef, data)
    }

    const removeData = data => {
        const selectedExpenseRef = ref(
            db,
            `/${uid}/lists/${listKey}/expenses/${data}`
        )
        remove(selectedExpenseRef)
    }

    const updateData = data => {
        const { index, payload } = data
        const selectedExpenseRef = ref(
            db,
            `/${uid}/lists/${listKey}/expenses/${index}`
        )
        set(selectedExpenseRef, payload)
    }

    const filterByKey = async (key, value) => {
        const expensesRef = ref(db, `/${uid}/lists/${listKey}/expenses/`)
        const result = await get(
            query(expensesRef, orderByChild(key), equalTo(value))
        )
        const expenses = convertObjectToArray(result.val()) ?? []
        dispatch(setExpenses(expenses))
    }

    const filterByYear = async year => {
        if (year.length > 3) {
            const userDataRef = ref(db, `/${uid}/lists/${listKey}/expenses/`)
            const result = await get(query(userDataRef))
            const expenses = convertObjectToArray(result.val())
            const filtered = expenses.filter(el => {
                return moment(el.value.date).format('YYYY').includes(year)
            })
            dispatch(setExpenses(filtered))
        } else await readData()
    }

    const filterByMonth = async month => {
        const userDataRef = ref(db, `/${uid}/list/${listKey}/expenses/`)
        const result = await get(query(userDataRef))
        const expenses = convertObjectToArray(result.val())
        const filtered = expenses.filter(el => {
            const {
                value: { date },
            } = el
            return moment(date)
                .format('MMMM')
                .toLowerCase()
                .includes(month.toLowerCase())
        })
        dispatch(setExpenses(filtered))
    }

    const filterByRange = async (
        key,
        start = 0,
        end = Number.MAX_SAFE_INTEGER
    ) => {
        const expensesRef = ref(db, `/${uid}/lists/${listKey}/expenses/`)
        const result = await get(
            query(expensesRef, orderByChild(key), startAt(start), endAt(end))
        )
        const arr = convertObjectToArray(result.val())
        const expenses = arr ?? []
        dispatch(setExpenses(expenses))
    }

    const filterByKeyword = async keyword => {
        keyword = keyword.toLowerCase()
        const filtered = expenses.filter(el => {
            const {
                value: { amount, category, subCategory, notes },
            } = el
            return (
                amount.toLowerCase().includes(keyword) ||
                category.toLowerCase().includes(keyword) ||
                subCategory.toLowerCase().includes(keyword) ||
                notes.toLowerCase().includes(keyword)
            )
        })
        return (keyword && dispatch(setExpenses(filtered))) || readData()
    }

    return {
        readData,
        writeData,
        removeData,
        updateData,
        filterByKey,
        filterByRange,
        filterByYear,
        filterByMonth,
        filterByKeyword,
        createList,
        getLists,
        getExpensesByChild,
    }
}

export default useDatabase
