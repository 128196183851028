import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart = ({ data }) => {
    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
    }
    return <Pie options={options} data={data} />
}
export default PieChart
