import ChartCard from '../../molecules/chartCard'
import Title from '../../atoms/typography/title'
import useMath from '../../../hooks/useMath'
import { setBarDataset, setPieDataset } from './statsHelper'
import { Select } from '../../atoms/select'
import { getKeyByValue, months } from '../../../utils/shared'
import { useEffect, useState } from 'react'
import LittleText from '../../atoms/typography/littleText'
import moment from 'moment'
import useCategories from '../../../hooks/useCategories'
import Input from '../../atoms/input'
import useDatabase from '../../../hooks/useDatabase'
import TextWithNumber from '../../molecules/textWithNumber'

const Stats = () => {
    const [selectedMonth, setSelectedMonth] = useState('january')
    const [selectedCategory, setSelectedCategory] = useState('')
    const [selectedSubCategory, setSelectedSubCategory] = useState('')
    const [year, setYear] = useState(moment().format('YYYY'))

    const { readData } = useDatabase()
    const { getSubCategories, getMacroCategories } = useCategories()
    const {
        overallTotal,
        currentYearTotal,
        currentMonthTotal,
        mostExpensiveByKeyAndDate,
        categoriesTotal,
        subCategoriesTotal,
        monthsTotalByYear,
        totalInCategoriesByMonth,
        monthsTotalByCategory,
        monthsTotalBySubCategory,
    } = useMath()

    const subCatsTotal = subCategoriesTotal()
    const catsTotal = categoriesTotal()
    const monthTotal = monthsTotalByYear(year)
    const totalInCatsByMonth = totalInCategoriesByMonth(
        getKeyByValue(months, selectedMonth)
    )
    const monthsTotalByCat = monthsTotalByCategory(selectedCategory)
    const monthsTotalBySubCat = monthsTotalBySubCategory(selectedSubCategory)

    const categoryData = setPieDataset(catsTotal)
    const subCategoryData = setPieDataset(subCatsTotal)
    const monthData = setBarDataset(monthTotal)
    const categoriesByMonthData = setPieDataset(totalInCatsByMonth)
    const monthsByCatData = setBarDataset(monthsTotalByCat)
    const monthsBySubCatData = setBarDataset(monthsTotalBySubCat)

    useEffect(() => {
        readData() //RESET FILTERS
        // eslint-disable-next-line
    }, [])

    return (
        <div className="text-center flex h-screen flex-col bg-primary-color-50">
            <div className="lg:p-1 grid grid-cols-2 lg:grid-cols-8 h-full overflow-x-hidden lg:mt-[5%] mt-[7%]">
                <div className="shadow-md rounded-md bg-white m-5 p-5 h-fit col-span-4">
                    <Title className="font-bold mb-1">
                        Here it is the math:
                    </Title>
                    <div className="flex flex-col">
                        <TextWithNumber
                            text="Overall total is"
                            number={overallTotal()}
                        />
                        <TextWithNumber
                            text="Current year total is"
                            number={currentYearTotal()}
                        />
                        <TextWithNumber
                            text="Current month total is"
                            number={currentMonthTotal()}
                        />
                        <TextWithNumber
                            text={`Most expensive category of all time is ${
                                mostExpensiveByKeyAndDate('category').category
                            } with`}
                            number={
                                mostExpensiveByKeyAndDate('category').amount
                            }
                        />
                        <TextWithNumber
                            text={`Most expensive category of this year is ${
                                mostExpensiveByKeyAndDate('category', {
                                    key: 'YYYY',
                                    value: moment().format('YYYY'),
                                }).category
                            } with`}
                            number={
                                mostExpensiveByKeyAndDate('category', {
                                    key: 'YYYY',
                                    value: moment().format('YYYY'),
                                }).amount
                            }
                        />
                        <TextWithNumber
                            text={`Most expensive category of this month is ${
                                mostExpensiveByKeyAndDate('category', {
                                    key: 'MMMM',
                                    value: moment().format('MMMM'),
                                }).category
                            } with`}
                            number={
                                mostExpensiveByKeyAndDate('category', {
                                    key: 'MMMM',
                                    value: moment().format('MMMM'),
                                }).amount
                            }
                        />
                        <TextWithNumber
                            text={`Most expensive sub-category of all time is ${
                                mostExpensiveByKeyAndDate('subCategory')
                                    .category
                            } with`}
                            number={
                                mostExpensiveByKeyAndDate('subCategory').amount
                            }
                        />
                        <TextWithNumber
                            text={`Most expensive sub-category of all this month is ${
                                mostExpensiveByKeyAndDate('subCategory', {
                                    key: 'MMMM',
                                    value: moment().format('MMMM'),
                                }).category
                            } with`}
                            number={
                                mostExpensiveByKeyAndDate('subCategory', {
                                    key: 'MMMM',
                                    value: moment().format('MMMM'),
                                }).amount
                            }
                        />
                        <TextWithNumber
                            text={`Most expensive sub-category of this year is ${
                                mostExpensiveByKeyAndDate('subCategory', {
                                    key: 'YYYY',
                                    value: moment().format('YYYY'),
                                }).category
                            } with`}
                            number={
                                mostExpensiveByKeyAndDate('subCategory', {
                                    key: 'YYYY',
                                    value: moment().format('YYYY'),
                                }).amount
                            }
                        />
                    </div>
                </div>
                <ChartCard
                    title="All time expenses partitions by category:"
                    data={categoryData}
                />
                <ChartCard
                    className="hidden lg:block"
                    title="All time expenses partitions by subcategory:"
                    data={subCategoryData}
                />
                <ChartCard
                    title={
                        <div className="flex flex-row justify-center items-center">
                            <LittleText className="mr-1">
                                Expenses partitions by selected month
                            </LittleText>
                            <Select
                                value={selectedMonth}
                                label="month"
                                className="w-1/2 ml-1"
                                onChange={setSelectedMonth}>
                                {Object.values(months)}
                            </Select>
                        </div>
                    }
                    data={categoriesByMonthData}
                />
                <ChartCard
                    title={
                        <div className="flex flex-row w-full items-center">
                            <LittleText>Most expensive months of</LittleText>
                            <Input
                                type="number"
                                placeholder="year"
                                onChange={setYear}
                                value={year}
                                className="w-1/2"
                            />
                        </div>
                    }
                    data={monthData}
                    type="bar"
                />
                <ChartCard
                    title={
                        <div className="flex flex-row justify-center items-center">
                            <LittleText className="mr-1">
                                Most expensive months by category:
                            </LittleText>
                            <Select
                                value={selectedCategory}
                                label="category"
                                className="w-1/2 ml-1"
                                onChange={setSelectedCategory}>
                                {getMacroCategories()}
                            </Select>
                        </div>
                    }
                    data={monthsByCatData}
                    type="bar"
                />
                <ChartCard
                    title={
                        <div className="flex flex-row justify-center items-center">
                            <LittleText className="mr-1">
                                Most expensive months by sub category:
                            </LittleText>
                            <Select
                                value={selectedSubCategory}
                                label="sub category"
                                className="w-1/2 ml-1"
                                onChange={setSelectedSubCategory}>
                                {getSubCategories()}
                            </Select>
                        </div>
                    }
                    data={monthsBySubCatData}
                    type="bar"
                />
            </div>
        </div>
    )
}
export default Stats
