const months = {
    1: 'january',
    2: 'february',
    3: 'march',
    4: 'april',
    5: 'may',
    6: 'june',
    7: 'july',
    8: 'august',
    9: 'september',
    10: 'october',
    11: 'november',
    12: 'december',
}

const categoryPalette = {
    halfOpacity: {
        car: 'rgba(249, 255, 164, 0.5)',
        home: 'rgba(255, 213, 158, 0.5)',
        subscriptions: 'rgba(255, 161, 161, 0.5)',
        personal: 'rgba(180, 255, 159, 0.5)',
        bad_stuff: 'rgba(190, 190, 190, 0.5)',
    },
    fullOpacity: {
        car: 'rgba(249, 255, 164, 1)',
        home: 'rgba(255, 213, 158, 1)',
        subscriptions: 'rgba(255, 161, 161, 1)',
        personal: 'rgba(180, 255, 159, 1)',
        bad_stuff: 'rgba(190, 190, 190, 1)',
    },
}

const removeWhiteSpace = text => text.replaceAll(' ', '')

const replaceUnderscoreWithSpace = text => text.replaceAll('_', ' ')

const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value)
}

const reduceByKeyAndValue = (array, value, key) =>
    array.reduce(
        (prev, curr) => {
            if (curr.value[key] === value)
                prev[value] += parseFloat(curr.value.amount)
            return prev
        },
        { [value]: 0 }
    )

const convertObjectToArray = obj => {
    return Object.entries(obj).map(([key, value]) => {
        return { key, value }
    })
}

const convertOrderedQueryToArray = (query, inverted = true) => {
    let arr = []
    query.forEach(item => {
        let obj = {}
        obj['key'] = item.key
        obj['value'] = item.val()
        inverted ? arr.unshift(obj) : arr.push(obj)
    })
    return arr
}

export {
    removeWhiteSpace,
    replaceUnderscoreWithSpace,
    months,
    categoryPalette,
    reduceByKeyAndValue,
    getKeyByValue,
    convertObjectToArray,
    convertOrderedQueryToArray,
}
