import Input from '../atoms/input'
import Button from '../atoms/button'
import { useEffect, useState } from 'react'
import MailIcon from '../../assets/icons/mail.svg'
import LockIcon from '../../assets/icons/lock.svg'
import Title from '../atoms/typography/title'
import Text from '../atoms/typography/text'

const LoginForm = ({
    className,
    onLoginClick,
    onSignupClick,
    goToRecoverPassword,
}) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [disabled, setDisabled] = useState(email === '' || password === '')

    useEffect(() => {
        setDisabled(email === '' || password === '')
    }, [email, password])

    const loginClick = () => {
        onLoginClick(email, password)
    }

    const signupClick = () => {
        onSignupClick(email, password)
    }

    return (
        <div
            className={`flex flex-col space-y-5 items-center justify-center px-10 py-5 bg-gray-100 rounded-md border-1 w-full h-full sm:h-fit sm:w-fit ${className}`}>
            <Title>Welcome to expense manager</Title>
            <Input
                autofocus
                icon={MailIcon}
                onChange={setEmail}
                type="text"
                placeholder="Insert your email"
            />
            <Input
                icon={LockIcon}
                onChange={setPassword}
                type="password"
                placeholder="Insert your password"
            />
            <div className="flex flex-row w-full items-end justify-end">
                <Button
                    disabled={disabled}
                    text="Signup"
                    onClick={signupClick}
                    className="outline-0 text-primary-color hover:text-black"
                />
                <Button
                    disabled={disabled}
                    text="Sign in"
                    onClick={loginClick}
                    className="text-white bg-primary-color-800 hover:text-black"
                />
            </div>
            <Text
                onClick={goToRecoverPassword}
                className="font-bold cursor-pointer">
                Forgot your password? Click here!
            </Text>
        </div>
    )
}

export default LoginForm
