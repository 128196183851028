import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChart = ({ data }) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
    }

    return <Bar options={options} data={data} />
}
export default BarChart
