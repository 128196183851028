import MenuIcon from '../../assets/icons/menu.svg'

const Dropdown = () => {
    return (
        <button
            className={`
            dropdown-toggle p-2.5 bg-transparent text-white
             transition duration-150 ease-in-out flex items-center whitespace-nowrap
            `}
            type="button"
            id="menu"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            <img src={MenuIcon} className="h-5" alt="icon" />
        </button>
    )
}
export default Dropdown
