import useRemoteConfig from './useRemoteConfig'

const useCategories = () => {
    const { getConfigData } = useRemoteConfig()

    const categories = getConfigData('categories')

    const getMacroCategories = () => {
        return Object.keys(categories)
    }

    const getSubCategories = () => {
        let all = []
        Object.values(categories).map(arr => all.push(...arr))
        return all
    }

    const getSubCategoriesByCategory = macroCat => {
        return categories[macroCat]
    }

    return { getMacroCategories, getSubCategories, getSubCategoriesByCategory }
}

export default useCategories
