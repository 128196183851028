import {
    fetchAndActivate,
    getValue,
    remoteConfig,
} from '../firebase/firebaseSDK'

const useRemoteConfig = () => {
    const fetchRemoteConfig = async () => {
        await fetchAndActivate(remoteConfig)
    }

    const getConfigData = key => {
        return JSON.parse(getValue(remoteConfig, key)._value)
    }

    return { getConfigData, fetchRemoteConfig }
}

export default useRemoteConfig
