import LoginForm from '../../molecules/loginForm'
import PasswordRecoverForm from '../../molecules/passwordRecoverForm'
import { useState } from 'react'
import { createUser, signIn } from '../../../firebase/firebaseSDK'
import useAlert from '../../../hooks/useAlert'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Auth = () => {
    const navigate = useNavigate()
    const [isPasswordRecover, setIsPasswordRecover] = useState(false)

    const { toggleAlert } = useAlert()

    const login = async (email, password) => {
        try {
            await signIn(email, password)
            toggleAlert(
                'Welcome back my friend!',
                "You're now logged in!",
                'SUCCESS'
            )
            navigate('/dashboard')
        } catch (e) {
            toggleAlert('Oh no!', 'Something went wrong!', 'ERROR')
        }
    }

    const signUp = async (email, password) => {
        try {
            await createUser(email, password)
            await signIn(email, password)
            navigate('/dashboard')
        } catch (e) {
            toggleAlert('Oh no!', 'Something went wrong!', 'ERROR')
        }
    }

    return (
        <div className="text-center flex h-screen flex-col bg-main_bg bg-center items-center justify-center">
            {!isPasswordRecover && (
                <>
                    <LoginForm
                        onLoginClick={login}
                        onSignupClick={signUp}
                        goToRecoverPassword={() => setIsPasswordRecover(true)}
                        className="shadow-2xl"
                    />
                </>
            )}
            {isPasswordRecover && (
                <>
                    <PasswordRecoverForm
                        className="shadow-2xl"
                        onBackClick={() => setIsPasswordRecover(false)}
                    />
                </>
            )}
        </div>
    )
}
export default Auth
