import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice'
import sharedReducer from './sharedSlice'
import dataReducer from './dataSlice'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        shared: sharedReducer,
        data: dataReducer,
    },
})
