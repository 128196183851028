const Text = props => {
    const { children, onClick, className } = props

    return (
        <p onClick={onClick} className={`${className} text-black text-sm`}>
            {children}
        </p>
    )
}

export default Text
