import {
    categoryPalette,
    replaceUnderscoreWithSpace,
} from '../../../utils/shared'

const setPieDataset = data => {
    return {
        labels: Object.keys(data).map(el => replaceUnderscoreWithSpace(el)),
        datasets: [
            {
                label: 'Categories',
                data: Object.values(data),
                backgroundColor: [
                    ...Object.values(categoryPalette.halfOpacity),
                ],
                borderColor: [...Object.values(categoryPalette.fullOpacity)],
                borderWidth: 1,
            },
        ],
    }
}

const setBarDataset = data => {
    return {
        labels: Object.keys(data),
        datasets: [
            {
                label: 'Months',
                data: Object.values(data),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    }
}

export { setPieDataset, setBarDataset }
