import Button from '../atoms/button'
import Title from '../atoms/typography/title'
import useSort from '../../hooks/useSort'

const SortTab = () => {
    const { sortByKey, sortByDate } = useSort()

    return (
        <div>
            <Title className="font-bold my-2 ">Sort by:</Title>
            <div className="flex flex-col">
                <Button
                    onClick={() => sortByKey('amount', true)}
                    text="Highest amount"
                    className="bg-primary-color hover:bg-primary-color-800 text-white m-1"
                />
                <Button
                    onClick={() => sortByKey('amount')}
                    text="Lowest amount"
                    className="bg-primary-color hover:bg-primary-color-800 text-white m-1"
                />
                <Button
                    onClick={() => sortByDate(true)}
                    text="Date ascend"
                    className="bg-primary-color hover:bg-primary-color-800 text-white m-1"
                />
                <Button
                    onClick={() => sortByDate()}
                    text="Date descend"
                    className="bg-primary-color hover:bg-primary-color-800 text-white m-1"
                />
            </div>
        </div>
    )
}

export default SortTab
