import Title from '../atoms/typography/title'
import Text from '../atoms/typography/text'
import { months } from '../../utils/shared'
import Input from '../atoms/input'
import useDatabase from '../../hooks/useDatabase'
import { useEffect, useState } from 'react'
import { Select } from '../atoms/select'
import useCategories from '../../hooks/useCategories'
import Button from '../atoms/button'

const FilterTab = () => {
    const { getMacroCategories, getSubCategories } = useCategories()
    const {
        filterByKey,
        // filterByRange,
        filterByYear,
        filterByMonth,
        filterByKeyword,
        readData,
    } = useDatabase()

    const [selectedMonth, setSelectedMonth] = useState('')
    // const [amountMin, setAmountMin] = useState('')
    // const [amountMax, setAmountMax] = useState('')
    const [selectedCategory, setSelectedCategory] = useState('')
    const [selectedSubCategory, setSelectedSubCategory] = useState('')
    const [year, setYear] = useState('')
    const [keyword, setKeyword] = useState('')

    const categories = getMacroCategories()

    const filterCategory = async value => {
        await filterByKey('category', value)
        setSelectedCategory(value)
        setSelectedSubCategory('')
    }

    const filterSubCategory = async value => {
        await filterByKey('subCategory', value)
        setSelectedSubCategory(value)
    }

    const filterByMonthSelect = async value => {
        await filterByMonth(value)
        setSelectedMonth(value)
    }
    const filterKeyword = async value => {
        await filterByKeyword(value)
        setKeyword(value)
    }

    // useEffect(() => {
    //     if (amountMax && amountMin)
    //         //filter by amount range
    //         filterByRange('amount', amountMin, amountMax)
    // }, [amountMin, amountMax])

    useEffect(() => {
        filterByYear(year)
    }, [year])

    const resetFilters = () => {
        setSelectedCategory('')
        setSelectedSubCategory('')
        // setAmountMax('')
        // setAmountMin('')
        setKeyword('')
        setSelectedMonth('')
        readData()
    }

    return (
        <div className="flex flex-col">
            <div className="flex flx-row items-center justify-between">
                <Title className="font-bold mb-1">Filter by</Title>
                <Button
                    text="reset filters"
                    className="bg-transparent text-primary-color m-2 shadow-none hover:bg-transparent hover:shadow-none active:bg-transparent active:shadow-none focus:bg-transparent focus:shadow-none"
                    onClick={resetFilters}
                />
            </div>
            <Text className="mb-1">keyword:</Text>
            <div className="text-center">
                <Input
                    placeholder="category, amount, notes..."
                    onChange={filterKeyword}
                    value={keyword}
                    type="text"
                    className="rounded-md pr-2"
                />
            </div>
            <Text className="mb-1">specific date:</Text>
            <div className="text-center">
                <Input
                    onChange={value => filterByKey('date', value)}
                    type="date"
                    className="rounded-md pr-2"
                />
            </div>
            <Select
                value={selectedMonth}
                label="by month"
                className="my-2"
                onChange={filterByMonthSelect}>
                {Object.values(months)}
            </Select>
            <Select
                value={selectedCategory}
                label="by category"
                className="my-2"
                onChange={filterCategory}>
                {categories}
            </Select>
            {/*<Select*/}
            {/*    value={selectedSubCategory}*/}
            {/*    label="by sub category"*/}
            {/*    className="my-2"*/}
            {/*    onChange={filterSubCategory}>*/}
            {/*    {getSubCategories}*/}
            {/*</Select>*/}
            <div className="flex flex-row">
                {/*<div className="flex flex-col mr-2">*/}
                {/*    <Text className="my-2">By amount range:</Text>*/}
                {/*    <Input*/}
                {/*        onChange={setAmountMin}*/}
                {/*        value={amountMin}*/}
                {/*        type="number"*/}
                {/*        placeholder="0"*/}
                {/*        className="rounded-md"*/}
                {/*    />*/}
                {/*    <Input*/}
                {/*        onChange={setAmountMax}*/}
                {/*        value={amountMax}*/}
                {/*        type="number"*/}
                {/*        placeholder="Lots of $$$"*/}
                {/*        className="mt-1 rounded-md"*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="ml-2 flex flex-col w-full">
                    <Text className="my-2">By year:</Text>
                    <Input
                        value={year}
                        onChange={setYear}
                        type="number"
                        placeholder="42"
                        className="rounded-md"
                    />
                </div>
            </div>
        </div>
    )
}

export default FilterTab
