import Text from '../atoms/typography/text'

const TextWithNumber = ({ text, number }) => {
    return (
        <div className="flex flex-row lg:justify-between text-left border-b border-b-gray-300">
            <Text>{text} &nbsp;</Text>
            <Text className="font-bold">{`${number} €`}</Text>
        </div>
    )
}
export default TextWithNumber
