import Card from './card'
import moment from 'moment'
import { replaceUnderscoreWithSpace } from '../../utils/shared'

const CardList = ({ expenses }) => {
    return expenses.map(el => {
        const { amount, category, date, notes, subCategory, paidWith } =
            el.value
        const formattedDate = moment(date).format('DD/MM/YYYY')
        const formattedCat = replaceUnderscoreWithSpace(category)
        return (
            <Card
                index={el.key}
                title={formattedCat}
                category={category}
                subCategory={subCategory}
                date={formattedDate}
                notes={notes ?? ''}
                amount={amount}
                paidWith={paidWith}
            />
        )
    })
}

export default CardList
