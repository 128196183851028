import Dropdown from '../atoms/dropdown'
import MenuItem from '../atoms/menuItem'
import Text from '../atoms/typography/text'

const Menu = ({ children, email }) => {
    return (
        <div className="dropstart relative">
            <Dropdown>Menu</Dropdown>
            <ul
                className="dropdown-menu min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1  hidden  m-0 bg-clip-padding border-none"
                aria-labelledby="menu">
                <Text className="select-none text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700">
                    {`Hi, ${email}`}
                </Text>
                <hr className="h-0 my-2 border border-solid border-t-0 border-gray-700 opacity-25" />
                <MenuItem link="/dashboard" text={'Dashboard'} />
                <MenuItem link="/stats" text={'Stats'} />
                {children}
            </ul>
        </div>
    )
}
export default Menu
