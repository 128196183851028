import { Outlet } from 'react-router'
import Navbar from '../molecules/navbar'

const RootPage = () => {
    return (
        <>
            <Navbar />
            <Outlet />
        </>
    )
}

export default RootPage
