import { useEffect } from 'react'

const useKeyboardListener = (cb, keycodes) => {
    const handler = ({ code }) => {
        if (keycodes.includes(code)) cb()
    }

    useEffect(() => {
        window.addEventListener('keydown', handler)
        return () => window.removeEventListener('keydown', handler)
    }, [])
}

export default useKeyboardListener
