import { removeWhiteSpace } from '../../utils/shared'
import classNames from 'classnames'

const Input = ({
    type,
    placeholder,
    className,
    onChange,
    icon,
    autofocus,
    maxLength,
    value,
}) => {
    return (
        <div className="w-full relative text-gray-600 focus-within:text-gray-400">
            {icon && (
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <img src={icon} className="h-5" alt="icon" />
                </span>
            )}
            <input
                value={value}
                maxLength={maxLength || 100}
                autoFocus={autofocus}
                name={
                    placeholder && removeWhiteSpace(placeholder.toLowerCase())
                }
                onChange={event => onChange(event.target.value)}
                type={type}
                placeholder={placeholder}
                className={classNames(
                    `w-full py-2 text-sm text-gray-900 bg-gray-50 rounded focus:bg-white focus:text-gray-900 ${className}`,
                    {
                        'pl-10': !!icon,
                        'pl-2': !!!icon,
                    }
                )}
                autoComplete="off"
            />
        </div>
    )
}

export default Input
