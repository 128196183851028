import Icon from '../../design/atoms/icon'

const RadioButtonGroup = ({ className, radios, onSelect }) => {
    let domRadios = radios?.map(({ icon, value }) => {
        return (
            <div className={`form-check form-check-inline ${className}`}>
                <input
                    onClick={() => onSelect(value)}
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    id={`${value}_id`}
                    value={value}
                />
                <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor={`${value}_id0`}>
                    <Icon icon={icon} />
                </label>
            </div>
        )
    })
    return <div className="flex justify-center">{domRadios}</div>
}
export default RadioButtonGroup
