import { useEffect, useState } from 'react'
import Title from '../atoms/typography/title'
import Input from '../atoms/input'
import Button from '../atoms/button'
import { Select } from '../atoms/select'
import IconButton from '../atoms/iconButton'
import CloseIcon from '../../assets/icons/x.svg'
import CreditCardIcon from '../../assets/icons/card.svg'
import BankNotesIcon from '../../assets/icons/banknotes.svg'
import useDatabase from '../../hooks/useDatabase'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { hideModal } from '../../store/sharedSlice'
import useCategories from '../../hooks/useCategories'
import RadioButtonGroup from '../../assets/icons/radioButtonGroup'
import Text from '../atoms/typography/text'

export const Modal = () => {
    const dispatch = useDispatch()
    const { getSubCategoriesByCategory, getMacroCategories } = useCategories()
    const { updateData, writeData, readData } = useDatabase()
    const macroCats = getMacroCategories()

    const modalData = useSelector(state => state.shared.modal.data)

    const [selectedMacroCat, setSelectedMacroCat] = useState(
        modalData?.category ?? ''
    )
    const [selectedSubCat, setSelectedSubCat] = useState(
        modalData?.subCategory ?? ''
    )
    const [date, setDate] = useState(
        moment(modalData?.date).format('YYYY-MM-DD') ?? ''
    )
    const [amount, setAmount] = useState(modalData?.amount ?? '')
    const [notes, setNotes] = useState(modalData?.notes ?? '')
    const [paidWith, setPaidWith] = useState(modalData?.paidWith ?? '')

    const disabled =
        !selectedMacroCat || !selectedSubCat || !date || !amount || !paidWith

    const resetForm = () => {
        setAmount('')
        setNotes('')
        setDate('')
        setSelectedMacroCat('')
        setSelectedSubCat('')
        setPaidWith('')
    }

    const leftButtonClick = () => {
        modalData
            ? updateData({
                  index: modalData.index,
                  payload: {
                      category: selectedMacroCat,
                      subCategory: selectedSubCat,
                      amount,
                      date,
                      notes,
                      paidWith,
                  },
              })
            : writeData({
                  category: selectedMacroCat,
                  subCategory: selectedSubCat,
                  amount,
                  date,
                  notes: notes || 'No additional data',
                  paidWith,
              })
        resetForm()
        dispatch(hideModal())
    }

    useEffect(() => {
        readData()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="z-50">
            <div className="p-10 bg-primary-color-50 lg:rounded-md lg:w-1/2 w-full h-screen lg:h-full shadow-lg m-auto">
                <div className="flex flex-row justify-between items-center">
                    <Title>
                        {modalData
                            ? 'Update your expense'
                            : 'Insert a new expense'}
                    </Title>
                    <IconButton
                        icon={CloseIcon}
                        onClick={() => dispatch(hideModal())}
                    />
                </div>
                <div className="flex flex-row mt-5 pr-3">
                    <Text className="mr-2">Paid with</Text>
                    <RadioButtonGroup
                        radios={[
                            {
                                icon: CreditCardIcon,
                                label: 'Credit Card',
                                value: 'creditCard',
                            },
                            {
                                icon: BankNotesIcon,
                                label: 'Bank Notes',
                                value: 'bankNotes',
                            },
                        ]}
                        onSelect={setPaidWith}
                    />
                </div>
                <Select
                    value={selectedMacroCat}
                    label="Select the macro category"
                    className="my-5"
                    onChange={setSelectedMacroCat}
                    placeholder="Insert your email">
                    {macroCats}
                </Select>
                {selectedMacroCat && (
                    <Select
                        value={selectedSubCat}
                        label="Select the sub category"
                        className="my-5"
                        onChange={setSelectedSubCat}
                        placeholder="Insert your email">
                        {getSubCategoriesByCategory(selectedMacroCat)}
                    </Select>
                )}
                <Input
                    value={date}
                    className="my-5 w-full"
                    type="date"
                    onChange={setDate}
                    placeholder="Insert the amount"
                />
                <Input
                    value={amount}
                    className="my-5"
                    type="number"
                    onChange={setAmount}
                    placeholder="Insert the amount"
                />
                <Input
                    value={notes}
                    maxLength={50}
                    className="my-5"
                    type="text"
                    onChange={setNotes}
                    placeholder="Additional notes (max 50 chars)"
                />
                <div className="flex flex-row w-full items-end justify-end">
                    <Button
                        disabled={disabled}
                        onClick={leftButtonClick}
                        text={modalData ? 'update' : 'insert'}
                        className="bg-primary-color outline-primary-color outline-1 outline hover:bg-primary-color-800 text-white m-2"
                    />
                    {!modalData && (
                        <Button
                            disabled={disabled}
                            onClick={() => {
                                writeData({
                                    category: selectedMacroCat,
                                    subCategory: selectedSubCat,
                                    amount,
                                    date,
                                    notes,
                                })
                                resetForm()
                            }}
                            text="Insert and continue"
                            className="text-primary-color hover:text-white focus:text-white active:text-white outline-primary-color outline-1 outline my-2 disabled:bg-gray-300"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Modal
