import './App.css'
import { useDispatch, useSelector } from 'react-redux'
import { setUser, unsetUser } from './store/authSlice'
import { useEffect } from 'react'
import Alert from './design/atoms/alert'
import useRemoteConfig from './hooks/useRemoteConfig'
import { authListener } from './firebase/firebaseSDK'
import { RouterProvider, Routes } from 'react-router-dom'
import { getRouter, getRoutes } from './utils/routes'

function App() {
    const dispatch = useDispatch()
    const {
        title: alertTitle,
        text: alertText,
        show: showAlert,
        type: alertType,
    } = useSelector(state => state.shared.alert)

    const user = useSelector(state => state.auth.currentUser)

    const { fetchRemoteConfig } = useRemoteConfig()

    useEffect(() => {
        const fetchRemote = async () => {
            await fetchRemoteConfig()
        }
        fetchRemote().catch(err => console.log(err))
    }, [])

    useEffect(() => {
        authListener(user => {
            if (user)
                dispatch(
                    setUser({
                        email: user.email,
                        uid: user.uid,
                    })
                )
            else dispatch(unsetUser())
        })
    }, [])

    return (
        <>
            <Alert
                title={alertTitle}
                text={alertText}
                show={showAlert}
                type={alertType}
            />
            <RouterProvider router={getRouter(user)} />
        </>
    )
}
export default App
