const IconButton = ({ onClick, icon, className }) => {
    return (
        <div
            onClick={onClick}
            className={`rounded-full cursor-pointer 
             transform transition ease-in-out hover:shadow-lg hover:scale-110 ${className}`}>
            <img src={icon} className="h-5" alt="icon" />
        </div>
    )
}

export default IconButton
