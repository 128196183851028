import { Link } from 'react-router-dom'
import Text from './typography/text'

const MenuItem = ({ link, text }) => {
    return (
        <li>
            <Text className="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100">
                <Link to={link}>{text}</Link>
            </Text>
        </li>
    )
}
export default MenuItem
