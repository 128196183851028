const alertType = {
    SUCCESS: {
        svg: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
            </svg>
        ),
        colors: 'bg-teal-100 border-teal-500 text-teal-900',
        iconColor: 'text-teal-500',
    },
    WARNING: {
        svg: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
            </svg>
        ),
        colors: 'bg-orange-100 border-orange-500 text-orange-900',
        iconColor: 'text-orange-500',
    },
    ERROR: {
        svg: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
            </svg>
        ),
        colors: 'bg-red-100 border-red-500 text-red-900',
        iconColor: 'text-red-500',
    },
}

export const Alert = ({ title, text, show, type }) => {
    if (!show) return <></>
    return (
        <div
            id="alert-box"
            className={`${alertType[type].colors} z-50 shadow-md absolute w-full lg:w-1/6 top-0 left-0 right-0 border-t-4 ml-2 mt-2 rounded-b px-4 py-3`}
            role="alert">
            <div className="flex">
                <div className="p-1">{alertType[type].svg}</div>
                <div>
                    <p id="alert-title" className="font-bold">
                        {title}
                    </p>
                    <p id="alert-text" className="text-sm">
                        {text}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Alert
