import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    alert: {
        title: '',
        text: '',
        show: false,
        type: 'SUCCESS',
    },
    modal: {
        open: false,
        data: null,
    },
}

export const sharedSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        showAlert: (state, action) => {
            const { title, text, type } = action.payload
            state.alert.title = title
            state.alert.text = text
            state.alert.show = true
            state.alert.type = type
        },
        hideAlert: (state) => {
            const { title, text, show, type } = initialState
            state.alert.title = title
            state.alert.text = text
            state.alert.show = show
            state.alert.type = type
        },
        showModal: (state, action) => {
            state.modal.open = true
            const { data } = action.payload ?? ''
            if (data) state.modal.data = data
        },
        hideModal: (state) => {
            state.modal.open = false
            state.modal.data = initialState.modal.data
        },
    },
})

export const { showAlert, hideAlert, showModal, hideModal } =
    sharedSlice.actions

export default sharedSlice.reducer
