import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    expenses: [],
    lists: [],
    listName: '',
    key: 'NO LIST',
}

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setExpenses: (state, action) => {
            state.expenses = action.payload
        },
        setExpensesDetails: (state, action) => {
            state.listName = action.payload.listName
            state.key = action.payload.key
        },
        setLists: (state, action) => {
            state.lists = action.payload
        },
    },
})

export const { setExpenses, setExpensesDetails, setLists } = dataSlice.actions

export default dataSlice.reducer
