import React from 'react'
import { removeWhiteSpace } from '../../utils/shared'
import classNames from 'classnames'

const Button = ({ onClick, text, className, disabled }) => {
    const _text = text ?? 'click me'
    const name = removeWhiteSpace(_text.toLowerCase())

    return (
        <button
            type="button"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            name={name}
            disabled={disabled}
            className={classNames(
                `
                inline-block px-6 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded 
                hover:bg-primary-color-800 focus:bg-primary-color-800  
                focus:outline-none focus:ring-0 active:bg-primary-color-800 transition duration-150 ease-in-out
                disabled:text-white disabled:bg-gray-300 disabled:outline-gray-300
                ${className}`
            )}
            onClick={onClick}>
            {_text}
        </button>
    )
}

export default Button
