import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import useDatabase from '../../../hooks/useDatabase'
import FilterTab from '../../molecules/filterTab'
import CardList from '../../molecules/cardList'
import { hideModal, showModal } from '../../../store/sharedSlice'
import useKeyboardListener from '../../../hooks/useKeyboardListener'
import Modal from '../../molecules/modal'
import Title from '../../atoms/typography/title'
import IconButton from '../../atoms/iconButton'
import Plus from '../../../assets/icons/plus.svg'
import Text from '../../atoms/typography/text'
import Input from '../../atoms/input'
import Button from '../../atoms/button'
import SortTab from '../../molecules/sortTab'

const Dashboard = () => {
    const expenses = useSelector(state => state.data.expenses)
    const listKey = useSelector(state => state.data.key)
    const listDBName = useSelector(state => state.data.listName)
    const open = useSelector(state => state.shared.modal.open)

    const dispatch = useDispatch()
    const { readData, createList } = useDatabase()

    const [listName, setListName] = useState('')

    useEffect(() => {
        readData()
        // eslint-disable-next-line
    }, [])

    useKeyboardListener(() => dispatch(hideModal()), ['Escape'])

    return (
        <div className="text-center min-h-screen flex flex-col bg-primary-color-50">
            {open && (
                <div className="z-50 absolute min-h-screen grid content-center w-screen backdrop-blur-md backdrop-brightness-50">
                    <Modal />
                </div>
            )}
            <div className="w-full flex justify-center h-5/6 lg:mt-[5%] mt-[20%]">
                <div className="flex-auto text-left px-5 w-1/6 hidden lg:block self-start sticky top-20">
                    <FilterTab />
                    <SortTab />
                </div>
                <div className="w-4/6 flex-auto">
                    {listKey !== 'NO LIST' && (
                        <>
                            <div className="sticky top-20 z-10 bg-primary-color-50 pt-3 pb-3">
                                <Title>
                                    Your expenses from {listDBName} list:
                                </Title>
                            </div>
                            {!!!expenses.length && (
                                <Text>Your list is empty, add something</Text>
                            )}
                            <div className="lg:p-1 px-2 grid grid-cols-1 lg:grid-cols-4 gap-2 rounded-md overflow-x-hidden !overflow-y-scroll">
                                <CardList expenses={expenses} />
                            </div>
                        </>
                    )}
                    {listKey === 'NO LIST' && (
                        <div className="flex flex-col items-center">
                            <Text className="lg:w-1/2  mt-2">
                                You have no list for expenses, create a new one
                                please{' '}
                            </Text>
                            <Input
                                className="lg:w-1/4 w-1/2 mt-2"
                                value={listName}
                                type="text"
                                onChange={setListName}
                                placeholder="Your first list"
                            />
                            <Button
                                text={'Create list'}
                                className="mt-2 w-fit p-2 bg-primary-color hover:bg-primary-color-800 text-white"
                                onClick={() => {
                                    createList(listName)
                                    readData()
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            {listKey !== 'NO LIST' && (
                <IconButton
                    icon={Plus}
                    className="fixed m-5 lg:m-10 w-fit p-2 bottom-0 right-0 bg-primary-color hover:bg-primary-color-800 text-white"
                    onClick={() => dispatch(showModal())}
                />
            )}
        </div>
    )
}

export default Dashboard
