import { replaceUnderscoreWithSpace } from '../../utils/shared'

export const Select = ({
    label,
    className,
    children,
    onChange,
    value,
    disabled,
}) => {
    const htmlOptions =
        children &&
        children.map((el, idx) => (
            <option value={el} key={idx}>
                {replaceUnderscoreWithSpace(el)}
            </option>
        ))
    return (
        <select
            disabled={disabled}
            value={value}
            onChange={event => onChange && onChange(event.target.value)}
            className={`capitalize w-full py-2 text-sm text-gray-900 bg-gray-50 rounded focus:bg-white focus:text-gray-900 ${className}`}>
            {label && (
                <option value="" disabled>
                    {label}
                </option>
            )}
            {htmlOptions}
        </select>
    )
}
