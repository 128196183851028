import Input from '../atoms/input'
import Button from '../atoms/button'
import { useEffect, useState } from 'react'
import MailIcon from '../../assets/icons/mail.svg'
import Title from '../atoms/typography/title'
import { resetPassword } from '../../firebase/firebaseSDK'
import Text from '../atoms/typography/text'

const PasswordRecoverForm = ({ className, onBackClick }) => {
    const [email, setEmail] = useState('')
    const [emailSent, setEmailSent] = useState(false)
    const [disabled, setDisabled] = useState(email === '')

    useEffect(() => {
        setDisabled(email === '')
    }, [email])

    const recoverPassword = async () => {
        setDisabled(true)
        setEmailSent(true)
        setTimeout(() => {
            setDisabled(false)
            setEmailSent(false)
        }, 60000)
        await resetPassword(email)
    }

    return (
        <div
            className={`flex flex-col space-y-5 items-center justify-center px-10 py-5 bg-gray-100 rounded-md border-1 w-full h-full sm:h-fit sm:w-1/2 ${className}`}>
            <Title>Recover your password:</Title>
            <Input
                icon={MailIcon}
                onChange={value => setEmail(value)}
                className="my-5"
                type="text"
                placeholder="Insert your email"
            />
            <div className="flex flex-row w-full items-center justify-end">
                <Button
                    disabled={disabled}
                    text={`${
                        emailSent ? `Retry in 1 minute` : `Recover password`
                    }`}
                    onClick={recoverPassword}
                    className="text-white bg-primary-color my-2"
                />
            </div>
            <Text
                onClick={onBackClick}
                className="font-bold mt-3 ml-3 self-center cursor-pointer">
                Back to login
            </Text>
        </div>
    )
}

export default PasswordRecoverForm
