import { createBrowserRouter, Navigate } from 'react-router-dom'
import Dashboard from '../design/organisms/dashboard/dashboard'
import Stats from '../design/organisms/stats/stats'
import Auth from '../design/organisms/auth/auth'
import React from 'react'
import RootPage from '../design/organisms/root'

const getAuthenticatedPages = () => {
    return [
        {
            path: 'dashboard',
            element: <Dashboard />,
        },
        {
            path: 'stats',
            element: <Stats />,
        },
        {
            path: '*',
            element: <Navigate replace to="/dashboard" />,
        },
    ]
}

const isAuthenticated = () => {
    return [
        {
            path: '/',
            element: <RootPage />,
            children: getAuthenticatedPages(),
        },
    ]
}

const isNotAuthenticated = () => {
    return [
        {
            path: 'auth',
            element: <Auth />,
        },
        {
            path: '*',
            element: <Navigate replace to="/auth" />,
        },
    ]
}

export const getRouter = user =>
    createBrowserRouter(user ? isAuthenticated() : isNotAuthenticated())
