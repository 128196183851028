import { hideAlert, showAlert } from '../store/sharedSlice'
import { useDispatch } from 'react-redux'

const useAlert = () => {
    const dispatch = useDispatch()

    const toggleAlert = (title, text, type) => {
        dispatch(showAlert({ title, text, type }))
        setTimeout(() => dispatch(hideAlert()), 3000)
    }

    return { toggleAlert }
}

export default useAlert
